// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-accounts-js": () => import("./../../../src/pages/accounts.js" /* webpackChunkName: "component---src-pages-accounts-js" */),
  "component---src-pages-externals-js": () => import("./../../../src/pages/externals.js" /* webpackChunkName: "component---src-pages-externals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-messages-js": () => import("./../../../src/pages/messages.js" /* webpackChunkName: "component---src-pages-messages-js" */),
  "component---src-pages-transaction-js": () => import("./../../../src/pages/transaction.js" /* webpackChunkName: "component---src-pages-transaction-js" */)
}

